import { ApiService } from "@/core/shared/services/ApiService";

import type {
  Campaign,
  CampaignApprovalDraftFormData,
  CampaignApprovalFormData,
  CampaignDraft,
  CampaignsLogs,
  CampaignStats,
  CampaignUpdated,
  RecurringCampaignExtended,
} from "@/modules/campaign/services/CampaignService.types";

/**
 * @class CampaignService
 */
export default class CampaignService {
  /**
   * Approve campaign
   * @endpoint /admin/campaigns/{campaignId}/approve
   * @httpMethod PUT
   * @param campaignId {number} Campaign id
   * @param data {CampaignApprovalFormData} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CampaignUpdated>}
   */
  static approve(
    campaignId: number,
    data: CampaignApprovalFormData
  ): Promise<CampaignUpdated> {
    return ApiService.put(`/admin/campaigns/${campaignId}/approve`, {
      ...data,
    });
  }

  /**
   * Approve campaign draft
   * @endpoint /admin/campaigns/{campaignId}/drafts/approve
   * @httpMethod PUT
   * @param campaignId {number} Campaign id
   * @param data {CampaignApprovalFormData} Data
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CampaignUpdated>}
   */
  static approveDraft(
    campaignId: number,
    data: CampaignApprovalDraftFormData
  ): Promise<CampaignUpdated> {
    return ApiService.put(
      `/admin/campaigns/${campaignId}/drafts/approve`,
      data
    );
  }

  /**
   * Reject campaign
   * @endpoint /admin/campaigns/{campaignId}/reject
   * @httpMethod PUT
   * @param campaignId {number} Campaign id
   * @param reason {string} Reason
   * @throws {ApiServiceError}
   * @return {Promise<CampaignUpdated>}
   */
  static reject(campaignId: number, reason: string): Promise<CampaignUpdated> {
    return ApiService.put(`/admin/campaigns/${campaignId}/reject`, {
      reason: reason,
    });
  }

  /**
   * Reject campaign draft
   * @endpoint /admin/campaigns/{campaignId}/drafts/reject
   * @httpMethod PUT
   * @param campaignId {number} Campaign id
   * @param reason {string} Reason
   * @throws {ApiServiceError}
   * @return {Promise<CampaignUpdated>}
   */
  static rejectDraft(
    campaignId: number,
    reason: string
  ): Promise<CampaignUpdated> {
    return ApiService.put(`/admin/campaigns/${campaignId}/drafts/reject`, {
      rejected_reason: reason,
    });
  }

  /**
   * Get data campaign
   * @endpoint /admin/campaigns/{campaignId}
   * @httpMethod GET
   * @param campaignId {number} Campaign id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<Campaign>}
   */
  static get(campaignId: number): Promise<Campaign> {
    return ApiService.get(`/admin/campaigns/${campaignId}`);
  }

  /**
   * Get latest data of a campaign on the onboarding approval
   * @endpoint /admin/campaigns/last-pending-approvals
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static getLatestPendingApproval(): Promise<Campaign> {
    return ApiService.get(`/admin/campaigns/latest-pending-approvals`);
  }

  /**
   * Get latest data of a campaign edition on the onboarding approval
   * @endpoint /admin/campaigns/drafts/latest-pending-approval
   * @httpMethod GET
   * @throws {BadRequestApiServiceError}
   * @return {Promise<CreatorExtended>}
   */
  static getLatestPendingEditionApproval(): Promise<Campaign> {
    return ApiService.get(`/admin/campaigns/drafts/latest-pending-approval`);
  }

  /**
   * Get data recurring campaign
   * @endpoint /admin/recurring-campaigns/{campaignId}
   * @httpMethod GET
   * @param campaignId {number} Campaign id
   * @throws {BadRequestApiServiceError}
   * @return {Promise<RecurringCampaignExtended>}
   */
  static getRecurringCampaign(
    campaignId: number
  ): Promise<RecurringCampaignExtended> {
    return ApiService.get(`/admin/recurring-campaigns/${campaignId}`);
  }

  /**
   * @endpoint /campaign/campaign_id/stats
   * @httpMethod PUT
   * @param campaignId
   */
  static stats(campaignId: number): Promise<CampaignStats> {
    return ApiService.get(`/admin/campaigns/${campaignId}/stats`);
  }

  /**
   * Get campaigns logs
   * @endpoint /admin/campaigns/${campaignId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param campaignId {number} Campaign id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @return {Promise<CampaignsLogs>}
   */
  static logs(campaignId: number, page = 0, rows = 25): Promise<CampaignsLogs> {
    return ApiService.get(
      `/admin/campaigns/${campaignId}/logs/${page}/${rows}`
    );
  }

  /**
   * Get recurring campaigns logs
   * @endpoint /admin/recurring-campaigns/${campaignId}/logs/${page}/${rows}
   * @httpMethod GET
   * @param campaignId {number} Campaign id
   * @param page {number} Page
   * @param rows {number} Number of rows to load
   * @return {Promise<CampaignsLogs>}
   */
  static logsRecurringCampaigns(
    campaignId: number,
    page = 0,
    rows = 25
  ): Promise<CampaignsLogs> {
    return ApiService.get(
      `/admin/recurring-campaigns/${campaignId}/logs/${page}/${rows}`
    );
  }

  static campaignDraft(campaignId: number): Promise<CampaignDraft> {
    return ApiService.get(`/admin/campaigns/${campaignId}/drafts`);
  }
}
